
import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import './assets/css/main.css';
import Context from "./context";
import Footer from './footer.js';
import Home from './home'
import Home2 from './home2'
import Home3 from './home3'
import Home4 from './home4'
import Home5 from './home5'
import Home6 from './home6'
import Home7 from './home7'
import Home8 from './home8'
import Home9 from './home9'
import Home10 from './home10'
import Home11 from './home11'
import Home12 from './home12'
import Home13 from './home13'
import Home14 from './home14'
import Home15 from './home15'
import Home16 from './home16'
import Home17 from './home17'
import Home18 from './home18'
import Home19 from './home19'
import Home20 from './home20'
import Home21 from './home21'
import Home22 from './home22'
import Home23 from './home23'
import Home24 from './home24'
import Home25 from './home25'
import Home26 from './home26'
import Home27 from './home27'
import Home28 from './home28'
import Home29 from './home29'
import Home30 from './home30'
import Done from './done'
import ShippingPolicy from './shipping-policy';
import RefundPolicy from './refund-policy';
import PrivacyPolicy from './privacy-policy';
import TermsOfService from './terms-of-service';
class App extends Component {
  constructor() {
    super();
    this.state = {

    }

  }
  render() {
    return (
      <BrowserRouter >
      <Context.Provider
        value={
          {
            value: this.state,
            actions: {
            }
          }
        }
      >
<Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/item2" component={Home2} />
      <Route exact path="/item3" component={Home3} />
      <Route exact path="/item4" component={Home4} />
      <Route exact path="/item5" component={Home5} />
      <Route exact path="/item6" component={Home6} />
      <Route exact path="/item7" component={Home7} />
      <Route exact path="/item8" component={Home8} />
      <Route exact path="/item9" component={Home9} />
      <Route exact path="/item10" component={Home10} />
      <Route exact path="/item11" component={Home11} />
      <Route exact path="/item12" component={Home12} />
      <Route exact path="/item13" component={Home13} />
      <Route exact path="/item14" component={Home14} />
      <Route exact path="/item15" component={Home15} />
      <Route exact path="/item16" component={Home16} />
      <Route exact path="/item17" component={Home17} />
      <Route exact path="/item18" component={Home18} />
      <Route exact path="/item19" component={Home19} />
      <Route exact path="/item20" component={Home20} />
      <Route exact path="/item21" component={Home21} />
      <Route exact path="/item22" component={Home22} />

      <Route exact path="/item23" component={Home23} />
      <Route exact path="/item24" component={Home24} />
      <Route exact path="/item25" component={Home25} />
      <Route exact path="/item26" component={Home26} />
      <Route exact path="/item27" component={Home27} />
      <Route exact path="/item28" component={Home28} />
      <Route exact path="/item29" component={Home29} />
      <Route exact path="/item30" component={Home30} />

      
      <Route  path="/done" component={Done} />
      <Route  path="/RefundPolicy" component={RefundPolicy} />
      <Route  path="/PrivacyPolicy" component={PrivacyPolicy} />
      <Route  path="/TermsOfService" component={TermsOfService} />
      <Route  path="/ShippingPolicy" component={ShippingPolicy} />
      </Switch>
      <Footer/>
      </Context.Provider>
    </BrowserRouter>
    );
  }
}

export default App;




